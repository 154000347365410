<template>
  <div>
    <div class="header">
      <el-form
          ref="dateForm"
          :model="formItems"
      >
        <el-form-item style="line-height: 50px" label="月报时间" required>
          <el-date-picker
              v-model="formItems.date"
              :disabled-date="disabledDate"
              type="month"
              format="YYYY 年 MM 月"
              value-format="YYYY-MM"
              placeholder="请选择月报时间">
          </el-date-picker>
        </el-form-item>
      </el-form>
    </div>
    <div class="part-1">
      <p>生产月报一经提交将无法再次修改，提交前请仔细核对 “月报时间” 和 “生产数据” 。</p>
    </div>
    <el-form
      :model="formItems"
      ref="reportForm"
      label-width="80px"
    >
      <el-row v-for="(item,index) in formItems.materialCodes" :key="item">
        <el-col :span="4">
          <el-form-item
              label="生产物料"
              label-width="80px"
              :prop="'materialCodes.' + index + '.modelId'"
              :rules="[
                { required: true, message: '生产物料不能为空', trigger: 'blur' },
              ]"
          >
            <el-select v-model="item.modelId" placeholder="请选择生产物料" @change="changeOption" filterable>
              <el-option
                  v-for="code in modelList"
                  :key="code.id"
                  :label="code.name"
                  :value="code.id"
                  :disabled="code.disabled"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <span class="span-6">——</span>
        <el-col :span="6">
          <el-form-item
              label="生产数量"
              :prop="'materialCodes.' + index + '.productionNum'"
              :rules="numRules"
          >
            <el-input v-model.number="item.productionNum" type="text" autocomplete="off" placeholder="请填写生产数量" maxlength="7"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6" class="btn-common">
          <el-button type="danger" @click.prevent="removeDomain(item)">删除</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-form-item>
          <el-button icon="el-icon-plus" @click="addDomain">添加生产明细</el-button>
        </el-form-item>
      </el-row>
    </el-form>
    <el-row style="margin-left: 40px;margin-top: 50px">
      <el-button @click="callBack">返回</el-button>
      <el-button style="margin-left: 50px" type="primary" @click="submitReport('reportForm')">提交月报</el-button>
    </el-row>
  </div>
</template>

<script>
import {ElMessage, ElMessageBox} from "element-plus";
import reportAddApi from '@/apis/report/reportAdd'
export default {
  data() {
    const validateNum = (rule, value, callback) => {
      if (value > 1000000) {
        callback(new Error('数量不能超过100万'))
      }else {
        callback()
      }
    }
    return {
      haveReportMonth: [],
      modelList: [],
      formItems: {
        date: '',
        materialCodes: [{
          modelId: '',
          productionNum: '',
        }]
      },
      numRules: [
        { required: true, message: '生产数量不能为空' },
        { type: 'number', message: '生产数量必须是数字' },
        { validator: validateNum },
      ]
    }
  },
  created() {
    // 获取已有月报的日期 haveReportMonth
    this.getManufacturerAllReport()
    // 获取厂商物料集合
    this.getModelList()
  },
  methods: {
    getModelList() {
      reportAddApi.getModelList().then(res => {
        this.modelList = res.data
      })
    },
    getManufacturerAllReport() {
      reportAddApi.getManufacturerAllReport().then(res => {
        this.haveReportMonth = []
        if (res.data.length > 0) {
          res.data.map(item => {
            this.haveReportMonth.push(item.year + '-' + item.month)
          })
        }
        console.log(this.haveReportMonth)
      })
    },
    disabledDate(time) {
      const date = time.getFullYear() + '-' + (time.getMonth() + 1);
      const nowDate = new Date();
      nowDate.setMonth(nowDate.getMonth() - 1);
      return (this.haveReportMonth.indexOf(date) > -1 || time.getTime() > nowDate.getTime())
    },
    removeDomain(item) {
      const index = this.formItems.materialCodes.indexOf(item)
      if (this.formItems.materialCodes.length <= 1) {
        ElMessage.warning("最少有一个生产物料")
        return
      }
      if (index !== -1) {
        this.formItems.materialCodes.splice(index, 1)
        const lists = this.formItems.materialCodes.map((item) => {return item.modelId})
        this.modelList.forEach(item => {
          if (lists.indexOf(item.id) > -1) {
            item.disabled = true
          }else {
            item.disabled = false
          }
        })
      }
    },
    addDomain() {
      if (this.modelList.length <= this.formItems.materialCodes.length) {
        ElMessage.warning('已达物料上限')
        return
      }
      this.formItems.materialCodes.push({
        modelId: '',
        productionNum: '',
        key: Date.now()
      });
    },
    changeOption() {
      let lists = []
      this.formItems.materialCodes.forEach(item => {
        if (item.modelId) {
          lists.push(item.modelId)
        }
      })
      this.modelList.forEach(item => {
        if (lists.indexOf(item.id) > -1) {
          item.disabled = true
        }else {
          item.disabled = false
        }
      })
    },
    handleInput(index,data) {
      if (data > 1000000) {
        this.formItems.materialCodes[index].productionNum = 1000000
      }
    },
    callBack() {
      this.$router.push({
        path: '/odm/report/list',
      })
    },
    submitReport(formName) {
      console.log(this.formItems)
      if (!this.formItems?.date ?? 0) {
        ElMessage.warning('请选择月报时间')
        return
      }
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return false
        }else {
          const params = []
          for (const materialCode of this.formItems.materialCodes) {
            const form = {}
            form.date = this.formItems.date
            form.modelId = materialCode.modelId
            form.productionNum = materialCode.productionNum
            params.push(form)
          }
          ElMessageBox.confirm('提交后将无法对月报进行修改，请确认是否填写完整？','提示',{
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(() => {
            reportAddApi.saveReport(params).then(res => {
              ElMessage.success("提交成功")
              this.callBack()
            }).catch(err => {
              ElMessage.success("提交失败")
            })
          }).catch(() => {
            ElMessage({
              type: 'info',
              message: '取消提交',
            })
          })
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.header {
  background: #ffffff;
  height: 60px;
  display: flex;
  align-items: center;
}
:deep(.el-form-item) {
  display: flex;
  margin: 10px 0;
}
.part-1 {
  //background: grey;
  height: 40px;
  line-height: 40px;
}
.span-6 {
  margin-left: 6px;
  line-height: 60px;
}
.btn-common {
  margin-left: 20px;
  line-height: 60px;
}
</style>
