import api from '@/apis/api'
/**
 * 月报相关api
 */
export class ReportAddApi extends api {

    getModelList() {
        return this.request({
            url: `${this.servicePath}/${this.modulePath}/getModelList`,
            method: 'get'
        })
    }

    getManufacturerAllReport() {
        return this.request({
            url: `${this.servicePath}/${this.modulePath}/getManufacturerAllReport`,
            method: 'get'
        })
    }

    saveReport(params) {
        return this.request({
            url: `${this.servicePath}/${this.modulePath}/save`,
            method: 'post',
            data: params
        })
    }
}

export default new ReportAddApi('reportAdd')
